import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import queryString from "query-string";
import answered from "../assets/images/message.svg";
import Connect from "../connect";
import plus from "../assets/images/plus.svg";
import brief from "../assets/images/briefcase.svg";

export default function BioSample({ location }) {
  const [content, setfeatued] = useState({});
  useEffect(() => {
    if (queryString.parse(location.search).id === "87654") {
      setfeatued({
        name: "Dr. Barbieri, M, FACODG",
        designation: "Reproductive Endocrinologist and Infertility Specialist",
        Locaation: "Portland, OR",
        education: "ORM Fertility",
        info:
          "For over 30 years, ORM Fertility has been at the forefront of fertility treatment, providing personalized patient care, cutting-edge technology, and some of the highest success rates in the industry. We are honored to also be a trusted resource for the LGBTQ+ community. Dr. Barbieri holds a bachelor degree with distinction from Yale University, and completed her medical training at University of Connecticut School of Medicine, Northwestern University McGaw Medical Center, and the Weill Cornell Center for Reproductive Medicine.",
        questionCount: "38",
        PatientThankxCount: "24",
        media:
          "https://cosmic-s3.imgix.net/54133ec0-8fc8-11ea-a782-7105b283a144-Barbieri-F.png",
      });
    } else if (queryString.parse(location.search).id === "60782") {
      setfeatued({
        name: "Elizabeth Turner",
        designation: "Genetic Counselor and Co-Founder",
        Locaation: "Los Angeles, CA",
        education: "Advanced Tele-Genetic Counseling",
        info:
          "We offer comprehensive preconception and prenatal genetic counseling services for patients with genetic conditions in the family, those seeking preconception or prenatal screening and diagnostic tests, high-risk pregnancies in which genetic counseling is recommended, couples who have difficulty conceiving or patients with difficulty maintaining a pregnancy, those whose ultrasound is suspicious for a potential genetic condition, patients undergoing invasive diagnostic procedures, as well as those who may have general questions regarding how genetics can impact their children.",
        questionCount: "38",
        PatientThankxCount: "24",
        media:
          "https://cosmic-s3.imgix.net/692476d0-8fc8-11ea-a782-7105b283a144-Elizabeth-F.png",
      });
    } else if (queryString.parse(location.search).id === "3024") {
      setfeatued({
        name: "Elizabeth Turner",
        designation: "Infertility Specialist",
        Locaation: "Barcelona, Spain",
        education: "Fertty International",
        info:
          "Specializing in Medical Tourism for patients living abroad, Fertty offers the most attractive Refund Guarantee program in the field. With free telemedicine consults available in all timezones, Fertty makes travelling for IVF and egg freezing cycles accessible and affordable.",
        questionCount: "27",
        PatientThankxCount: "24",
        media:
          "https://cosmic-s3.imgix.net/796bf1d0-8fc8-11ea-a782-7105b283a144-maria-f.png",
      });
    } else if (queryString.parse(location.search).id === "35317") {
      setfeatued({
        name: "Mark Trolice, MD, FACOG",
        designation: "Infertility Specialist",
        Locaation: "Orlando, FL",
        education:
          "Fertility CARE: The IVF Center, Professor, UCF-College of Medicine",
        info:
          "Dr. Trolice is double Board-certified in REI and OB/GYN, maintains annual recertification, and has been awarded the American Medical Association’s “Physicians’ Recognition Award” annually. He holds the unique distinction of being a Fellow in all three American Colleges of OB/GYN, of Surgeons, and of Endocrinology. His colleagues select him as Top Doctor in America® annually, one among the top 5% of doctors in the U.S. In 2018, he was awarded the “Social Responsibility Award” by the National Polycystic Ovary Syndrome Association. For ten years his foundation, Fertile Dreams, organized seminars to increase fertility awareness and granted national scholarships for those unable to afford in vitro fertilization (IVF) treatment.",
        questionCount: "97",
        PatientThankxCount: "24",
        media:
          "https://cosmic-s3.imgix.net/8b9c2a00-8fc8-11ea-a782-7105b283a144-mark-t.png",
      });
    }
  }, []);
  const [choosebox, setchoosebox] = useState(false);
  const [expertData, setExpert] = useState({});

  const ExpertActions = ({ expert }) => (
    <Button
      variant="blue"
      href=""
      onClick={() => {
        setchoosebox(true);
        setExpert(expert);
      }}
      className="connect-special-expert"
    >
      Connect
    </Button>
  );

  return (
    <>
      <div className="box-grey-mobile-expert"></div>
      <div className="FeaturedExpert biosmaple">
        <div className="content">
          <div className="Bio seocheck">
            <article className="FeaturedExpert">
              <div className="content">
                <div className="box">
                  <Row>
                    <Col md={12} lg={4}>
                      <img src={content.media} className="profilepic" />
                      <div className="counts d-none d-lg-block pt-3">
                        {/*  <div className="answered">
                      <img src={answered} />
                        <span>
                          <strong> {content.questionCount} </strong> Questions
                          Answered
                        </span>
                      </div>*/}
                      </div>
                    </Col>

                    <Col md={12} lg={8}>
                      <div className="details">
                        <div className="details-top">
                          <div className="details-top-content">
                            <div className="box_sched">
                              <h1>{content.name}</h1>
                            </div>
                            <div className="location">{content.Locaation}</div>
                            <div className="expert-specialities">
                              <img src={plus} />
                              <h3> {content.designation} </h3>
                            </div>
                            <div className="expert-affiliation">
                              <img src={brief} />

                              <h2> {content.education} </h2>
                            </div>
                          </div>
                        </div>
                        <div className="featured_social">
                          <ExpertActions expert={content} />
                        </div>
                        {/*     <div className="counts d-lg-none">
                        <div className="answered">
                          <span>
                            <strong>{content.questionCount}</strong>
                          </span>
                          <img src={answered} />
                        </div>
                      </div>
                      <br />
                      <p>{content.info}</p>
                    </div>
                  </Col>
                </Row>
                      </div>*/}
                        <br />
                        <p>{content.info}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </article>
          </div>
        </div>
        <Connect
          status={choosebox}
          setchoosebox={setchoosebox}
          expertData={content}
        />
      </div>
    </>
  );
}
